<template>
  <pro-menu-layout>
    <div class="q-pa-md">
      <pro-deck
        :title="getRes('System.Module.Shortlist.Result')"
        :cards="tendererList"
        :statuses="statuses"
        v-slot="{ card: tenderer, status }"
      >
        <pro-deck-card
          :title="tenderer.tendererName"
          :status="status.label"
          :status-color="status.color"
          :expandable="tenderer.isShowAssessorResponse"
          default-closed
        >
          <div
            v-if="tenderer.assessorResponses.length === 0"
            class="text-center"
          >
            No Responses Found.
          </div>
          <div v-else class="q-gutter-y-sm">
            <div
              v-for="(response, index) in tenderer.assessorResponses"
              :key="index"
              class="row no-wrap items-center q-col-gutter-x-md"
            >
              <div class="col-auto">
                <div class="text-h5">{{ response.assessorName }}</div>
              </div>
              <div class="col-auto">
                <q-badge
                  outline
                  :color="getShortlistStatusColor(response.isShortlist)"
                  >{{ getShortlistStatusStr(response.isShortlist) }}</q-badge
                >
              </div>
              <q-space />
              <div class="col-auto">
                {{
                  String.format(
                    getRes("LastSubmission"),
                    $proSmart.common.getFormattedDate(
                      new Date(response.dateSubmitted)
                    )
                  )
                }}
              </div>
              <div class="col-auto">
                <q-btn
                  no-caps
                  padding="xs md"
                  outline
                  color="primary"
                  :label="getRes('System.Button.View')"
                  @click="viewResponse(response)"
                />
              </div>
            </div>
          </div>

          <template #status v-if="tenderer.isShowAssessorResponse">
            <div class="q-ml-md" style="width: 300px">
              <pro-deck-card-percentage-bar
                :left-label="shortlistLabel"
                :left-color="shortlistColor"
                :left-value="tenderer.shortlistCount"
                :right-label="notShortlistLabel"
                :right-color="notShortlistColor"
                :right-value="tenderer.notShortlistCount"
              />
            </div>
          </template>
        </pro-deck-card>
      </pro-deck>
      <audit-trail-record-dialog ref="audit" title="Form.Title.AssessmentResults"/>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProDeckCardPercentageBar from "@/components/PROSmart/Deck/Status/ProDeckCardPercentageBar";
import AuditTrailRecordDialog from "@/components/PROSmart/AuditTrailRecordDialog";

export default {
  components: {
    ProMenuLayout,
    ProDeck,
    ProDeckCard,
    ProDeckCardPercentageBar,
    AuditTrailRecordDialog,
  },
  data() {
    return {
      shortlistLabel: this.getRes("System.Module.Shortlist.Shortlist"),
      notShortlistLabel: this.getRes("System.Module.Shortlist.NotShortlist"),
      shortlistColor: "green",
      notShortlistColor: "red",
      statuses: [
        {
          key: "Interest",
          label: this.getRes("System.OfferType.Interest"),
          color: "green",
        },
        {
          key: "NotInterest",
          label: this.getRes("System.OfferType.NotInterest"),
          color: "orange",
        },
        {
          key: "NoResponse",
          label: this.getRes("System.OfferType.NoResponse"),
          color: "purple",
        },
      ],
      tendererList: [],
    };
  },
  methods: {
    getShortlistStatusStr(isShortlist) {
      return isShortlist ? this.shortlistLabel : this.notShortlistLabel;
    },
    getShortlistStatusColor(isShortlist) {
      return isShortlist ? this.shortlistColor : this.notShortlistColor;
    },
    isShowAssessorResponse(tenderer) {
      return tenderer.responseType === "Interest";
    },
    viewResponse(response) {
      this.$refs.audit.showInfo(
        response.processId,
        this.$route.params.id,
        true
      );
    },
    countIf(arr, callback) {
      return arr.reduce(
        (count, item) => (callback(item) ? count + 1 : count),
        0
      );
    },
  },
  created() {
    this.$proSmart.tender
      .getAssessorShortlistTendererList(this, this.$route.params.id)
      .then((list) => {
        this.tendererList = list.map((tenderer, index) => ({
          ...tenderer,
          key: index,
          status: tenderer.responseType,
          isShowAssessorResponse: this.isShowAssessorResponse(tenderer),
          shortlistCount: this.countIf(
            tenderer.assessorResponses,
            (response) => response.isShortlist === true
          ),
          notShortlistCount: this.countIf(
            tenderer.assessorResponses,
            (response) => response.isShortlist === false
          ),
        }));
      });
  },
};
</script>

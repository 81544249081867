<template>
  <div>
    <div class="row no-wrap justify-between">
      <div class="text-h6" :class="`text-${leftColor}`">
        {{ leftValue }}/{{ sum }}
      </div>
      <div class="text-h6" :class="`text-${rightColor}`">
        {{ rightValue }}/{{ sum }}
      </div>
    </div>
    <div class="row no-wrap">
      <q-linear-progress
        :value="1"
        stripe
        :color="leftColor"
        size="md"
        :style="`width:${(leftValue / sum) * 100}%`"
      />
      <q-linear-progress
        :value="1"
        stripe
        :color="rightColor"
        size="md"
        :style="`width:${(rightValue / sum) * 100}%`"
      />
    </div>
    <div class="row no-wrap justify-between">
      <div class="text-h6" :class="`text-${leftColor}`">{{ leftLabel }}</div>
      <div class="text-h6" :class="`text-${rightColor}`">{{ rightLabel }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProDeckCardPercentageBar",
  props: {
    leftValue: {
      type: Number,
      required: true,
    },
    rightValue: {
      type: Number,
      required: true,
    },
    leftLabel: String,
    rightLabel: String,
    leftColor: {
      type: String,
      default: "primary",
    },
    rightColor: {
      type: String,
      default: "primary",
    },
  },
  computed: {
    sum() {
      return this.leftValue + this.rightValue;
    },
  },
};
</script>
